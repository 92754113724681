<template>
	<div>
		<CCard class="p-4 mt-2">
  
		
  
		<CRow class="mb-2">
		  <CCol lg="12">
			<div class="table-responsive-sm table-responsive-md">
			  <CDataTable
				:tableFilter="{ placeholder: 'Search here...' }"
				pagination
				:itemsPerPage="10"
				itemsPerPageSelect
				:loading="isLoading"
				:items="license_renewal_reminders_data"
				:fields="fields"
				hover
				striped
				small
				fixed
				custom
			  >
			  <template #status="{ item }">
					<td style="padding: 5px; vertical-align: inherit;">
						<CButton disabled size="sm" style="width: 74px;"
							:color="item.status | colorStatus">
							{{ item.status }}
						</CButton>
					</td>
				</template>
				<template #due_date="{ item }">
					<td style="padding: 5px;">
						{{ item.due_date }} <br>
						<CBadge v-if="item.renewal_badge != null" :color="item.status | colorStatus">
							<small>{{ item.renewal_text }}</small>
						</CBadge>
					</td>
				</template>
			  </CDataTable>
			 
			</div>
		  </CCol>
		</CRow>
	  </CCard>
	 
	
	</div>
  </template>
  <script>
  import config from "../../config.js";
  import axios from "../../axios";
  import Swal from "sweetalert2";
  // import Search from './search'
  import Datepicker from "vuejs-datepicker";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import moment from "moment";
  
  export default {
	mounted() {
	  
		this.getData(this.$route.params.id)
	 
	},
	
	watch: {},
	computed: {
	 
	},
	data() {
	  return {
		limit: 10,
		isLoading: false,
		config,
		fields: [
		  {
			key: "renewal_type",
			label: "Renewal Type",
		  },
		  {
			key: "status",
			label: "Status",
		  },
		  {
			key: "due_date",
			label: "Due Date",
		  },
		  
		 

		],
		currentPage: 1,
		
		
		license_renewal_reminders_data: [
		  {
		  	renewal_type: '',
		  	status: '',
		  	due_date: '',
		  	
		  	
		  }
		 
		],
  
	  };
	},
	name: "Tables",
	components: { Datepicker, vSelect },
	methods: {
		getData(id) {
		axios.get(`${config.api_path}/personnel/renewal-reminder/${id}`).then(res => {
			const {data} = res.data;
			this.license_renewal_reminders_data = data.map(rr => {
				let today = moment(new Date()).startOf('day').unix();
				let renewal_badge = '';
				let renewal_text = 'Due ' + moment(rr.due_date).fromNow(); //remaining_time
				// subtract 1 to match fromNow() function
				const diff_days = (moment(rr.due_date).diff(moment.unix(today), 'days')) - 1;
				if (diff_days < 0)
					renewal_badge = 'danger';
				else if (diff_days >= 0 && diff_days <= 7)
					renewal_badge = 'warning';
				else
					renewal_badge = 'info';

				// console.log(renewal_text, diff_days)
				rr.renewal_badge = renewal_badge;
				rr.renewal_text = renewal_text;
				rr.due_date = rr.due_date ? moment(rr.due_date).format('MMMM DD, YYYY') : '';
				return rr;
			})
		})
	 },
	
	  reloadData() {
		
	  },
	 
	 
  
	
	 
	},
  };
  </script>
  