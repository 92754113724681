<template>
    <div>
        <CCard class="p-4"> 
            <CRow><CButton size="sm" class="btn btn-link back-btn"
				@click="backToTable()"><i class="fa fa-arrow-left"></i> Back</CButton>
			</CRow>
            <CRow >        
						<CCol lg="1">
								<img style="max-height: 100px; max-width:100px;"  src="https://t3.ftcdn.net/jpg/02/48/42/64/360_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg">
								
						
						</CCol> 
						<CCol lg="4">
						<span >
							<h4 class="mb-0 text-primary">
                                {{dataParams.name}}
							</h4>
							<p>
							<small>{{dataParams.personnel_type_name == "" || dataParams.personnel_type_name == null ? "-" : dataParams.personnel_type_name.setting_name}}
							 </small><br/>
							<!-- <em>{{dataParams.vehicle_type ? dataParams.vehicle_type.setting_name : "-"}}</em><br /> -->
							<strong style="text-transform:uppercase; font-size:12px;color:green">{{dataParams.status ? dataParams.status : "-"}}</strong>
							</p>
						</span>
						</CCol>
						<!-- <CCol lg="7">
							<div style="text-align: right"> 
								<CDropdown 
											toggler-text=" Quick Add "  
											size="sm"
											color="success"
										
										>
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Renewal Reminder </i></CDropdownItem> 
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Service Reminder </i></CDropdownItem> 
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Incident </i></CDropdownItem>  
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Work Order </i></CDropdownItem>  
											<CDropdownItem v-if="config.getPermission('vehicle').view" @click="viewModal(item)"><i class="fa fa-eye" > Add Fuel Record</i></CDropdownItem>  
											

								</CDropdown>
							</div>

						</CCol> -->
						
      		</CRow>
              <CRow >
				<CCol lg="12">
				<CTabs variant="pills" :active-tab="0">
	      			<CTab title="Personnel Info">				
						<CRow class="mt-3">
							<CCol lg="6">
								<CCard class="p-1 mb-2">
									<CRow class="p-1">
										<CCol lg="12">
											<strong style="font-size:12px">Personnel Information</strong>
										</CCol>	
									</CRow>
									
									<CRow class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Name</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                                {{dataParams.name}}
                                         </CCol>
                                                            
									</CRow>
									<CRow class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Contact Number</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.contact_no ? dataParams.contact_no :"-"}}
                                        </CCol>
									</CRow>
                                    <CRow class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Email</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.email ? dataParams.email :"-"}}
                                        </CCol>
									</CRow>
                                    <CRow class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Birthdate</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.birthday ? dataParams.birthday :"-"}}
                                        </CCol>
									</CRow>
                                    <CRow class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Home Address</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.home_address ? dataParams.home_address :"-"}}
                                        </CCol>
									</CRow>
                                    <CRow class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Gender</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600" class="text-uppercase">
                                            {{dataParams.gender ? dataParams.gender :"-"}}
                                        </CCol>
									</CRow>
                                    <CRow class="ml-1 mr-1" >
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Contact Number</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.contact_no ? dataParams.contact_no :"-"}}
                                        </CCol>
									</CRow>
									<CRow class="ml-1 mr-1" >
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">License Expiry</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.license_expiry ? dataParams.license_expiry :"-"}}
                                        </CCol>
									</CRow>
									
								</CCard>
							</CCol>					
							<CCol lg="6">
								<CCard class="p-1 mb-2">
									<CRow class="p-1">
										<CCol lg="12">
											<strong style="font-size:12px">Benefits</strong>
										</CCol>	
									</CRow>
									<CRow class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Philhealth No.</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.philhealth_no ? dataParams.philhealth_no :"-"}}
                                        </CCol>
									</CRow>
									<CRow class="ml-1 mr-1">
											
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">SSS No.</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.sss_no ? dataParams.sss_no :"-"}}
                                        </CCol>
											
										</CRow>
										<CRow class="ml-1 mr-1">
											<CCol lg="4">
                                                <label style="font-size: 13px;">TIN No.</label>  
                                            </CCol>
                                            <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                                {{dataParams.tin_no ? dataParams.tin_no :"-"}}
                                            </CCol>
                                                                    
									</CRow>
                                    <CRow class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Pagibig No.</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.pag_ibig_no ? dataParams.pag_ibig_no :"-"}}
                                        </CCol>
                                    </CRow>
                                
											
										
								</CCard>
								<CCard class="p-1 mb-2">
									<CRow class="p-1">
										<CCol lg="12">
											<strong style="font-size:12px">Emergency Contact</strong>
										</CCol>	
									</CRow>
									<CRow class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Emergency Contact Name</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.emergency_name ? dataParams.emergency_name :"-"}}
                                        </CCol>
									</CRow>
									<CRow class="ml-1 mr-1">
											
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Emergency Contact No.</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.emergency_contact_no ? dataParams.emergency_contact_no :"-"}}
                                        </CCol>
										</CRow>
										
                                  
								</CCard>
							</CCol>	
							<CCol lg="6">
								<CCard class="p-1 mb-2">
									<CRow class="p-1">
												<CCol lg="12">
													<strong style="font-size:12px">Employment Details</strong>
												</CCol>	
									</CRow>
									<CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Personnel Type</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.personnel_type_name == "" || dataParams.personnel_type_name == null ? "-" : dataParams.personnel_type_name.setting_name}}
                                        </CCol>
                                    </CRow>
                                    <CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Employment Type</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600" class="text-capitalize">
                                            {{dataParams.employment_type}}
                                        </CCol>
                                    </CRow>
                                    <CRow  class="ml-1 mr-1" v-if="dataParams.employment_type == 'outsourced'">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Vendor</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.vendor ? dataParams.vendor.vendor_name :"-"}}
                                        </CCol>
                                    </CRow>
                                    <CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Employee ID</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.employee_id ? dataParams.employee_id :"-"}}
                                        </CCol>
                                    </CRow>
                                    <CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Depot</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.depot ? dataParams.depot.setting_name :"-"}}
                                        </CCol>
                                    </CRow>
                                    <CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Date Started</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.date_started ? dataParams.date_started :"-"}}
                                        </CCol>
                                    </CRow> 
                                    <CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Date of Separation</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.date_seperation ? dataParams.date_seperation :"-"}}
                                        </CCol>
                                    </CRow> 
                                    <CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Reason of Separation</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.reason_of_separation ? dataParams.reason_of_separation :"-"}}
                                        </CCol>
                                    </CRow> 	        	
                                    <CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Bank Account</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.bank_account ? dataParams.bank_account :"-"}}
                                        </CCol>
                                    </CRow> 
                                    <CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Daily Rate</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.daily_rate ? dataParams.daily_rate :"-"}}
                                        </CCol>
                                    </CRow>        	
                                    <CRow  class="ml-1 mr-1">
                                        <CCol lg="4">
                                            <label style="font-size: 13px;">Remarks</label>  
                                        </CCol>
                                        <CCol lg="8" style="font-weight:600;font-size: 13px;">
                                            {{dataParams.remarks ? dataParams.remarks :"-" }}
                                        </CCol>
                                    </CRow> 
                                   
								</CCard>
							</CCol>
						
						
						</CRow>
							
							
								
					</CTab>
					<CTab title="Renewal Reminders">
						<LicenseRenewalReminders />

					</CTab>
					<CTab title="Work Order" v-if="!is_trucking()">
						<WorkOrder />

					</CTab>
				</CTabs>
			</CCol>		
			</CRow>

        </CCard>
    </div>
  
	        	
		
</template>
<script>
import config from '../../config.js';
import axios from '../../axios';
import WorkOrder from './work_order_tab'
import LicenseRenewalReminders from './license_renewalreminders_tab'
export default {
	mounted(){  
		if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.id){
			this.getData();
		} 

	},
	data(){
		return{
			config,
			options:'',
			title:'',
			isLoading: false,
			formModal: false,
			showViewModal: false,
			dataListAll: [],
			dataList: [],
			dataListInactive: [],
			current_tab: "All",
			count: 0,
			depotName: "",
			depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			dataParams: {
				name: "",
				contact_no: "",
				email: "",
				birthday: "",
				home_address: "",
				gender: "",
				personnel_type_name: "",
				employment_type: "direct_hire",
				vendor_id: "",
				employee_id: "",
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
				date_started: "",
				date_seperation: "",
				philhealth_no: "",
				sss_no: "",
				status: "active",
				remarks: "",
				emergency_name: "",
				emergency_contact_no: "",
				bank_account: "",
				daily_rate: "",
				tin_no: "",
				pag_ibig_no: "",
				reason_of_separation: "",

			},
			editMode: false,
			selectedRow: {},
			showCollapse: false,

			setting_type : ''
		}
	},
	watch: {
		'dataParams.name' : function(val) {
			this.dataParams.name = val.toUpperCase()
		}
	},
	name: 'Tables',
	components: {WorkOrder,LicenseRenewalReminders},
	methods: { 
		 backToTable(){
	    	this.$router.push('/data/personnel')
	    },
		getData(){

			this.$showLoading(true)
			axios.get(config.api_path+"/personnel/"+this.$route.params.id)
			.then(response=>{
				this.$showLoading(false)
				this.dataParams = response.data.data
			})
			.catch(err => {
			this.$showLoading(false)
		}) 
    },
		
        submit(){
	    	var ax = {};
	    	if(this.dataParams.vendor_id == ""){
	    		this.dataParams.vendor_id = null;
	    	}

			if(this.dataParams.employment_type == 'direct_hire') {
				this.dataParams.vendor_id = null;
			}

	    	if(this.dataParams.employee_id == ""){
	    		this.dataParams.employee_id = null;
	    	}  

	    	if(this.dataParams.birthday == "" || this.dataParams.birthday == null){
	    		this.dataParams.birthday = null;
	    	}
	    	else{
	    		this.dataParams.birthday = moment(this.dataParams.birthday).format('YYYY-MM-DD');
	    	}

	    	if(this.dataParams.date_seperation == "" || this.dataParams.date_seperation == null){
	    		this.dataParams.date_seperation = null;
	    	}
	    	else{
	    		this.dataParams.date_seperation = moment(this.dataParams.date_seperation).format('YYYY-MM-DD');
	    	}

	    	if(this.dataParams.date_started == "" || this.dataParams.date_started == null){
	    		this.dataParams.date_started = null;
	    	}
	    	else{
	    		this.dataParams.date_started = moment(this.dataParams.date_started).format('YYYY-MM-DD');
	    	}

			this.dataParams.daily_rate = (this.dataParams.daily_rate) ? this.dataParams.daily_rate : 0;

			this.dataParams.is_company_with_agency = config.global_user_data.company && config.global_user_data.company.company_type.toLowerCase() === 'trucking with agency' ? 1 : 0;
	    	
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/personnel/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/personnel", this.dataParams)
	    	}
	    	ax.then(response => {
				const { status } = response.data;
				
				if(status === 'duplicate') {
					Swal.fire({
						title: 'Personnel name already exists.',
						text,
						icon: 'error', 
					})
						return;
				}

	    		var text = 'Personnel successfully added!';
	    		if(this.editMode){
	    			text = 'Personnel successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					name: "",
					contact_no: "",
					email: "",
					birthday: "",
					home_address: "",
					gender: "",
					personnel_type: "",
					employment_type: "direct_hire",
					vendor_id: "",
					employee_id: "",
					depot_id: "",
					date_started: "",
					date_seperation: "",
					philhealth_no: "",
					sss_no: "",
					status: "active",
					remarks: "",
					emergency_name: "",
					emergency_contact_no: "",
					bank_account: "",
					daily_rate: "",
				}
	    		this.getData();
	    		this.getDataInactive();
				this.getDataAll();
	    	})
	    },


        getVendor(){

            axios.get(config.api_path+'/vendor?page=1&limit=10000000')
            .then(response => {
                this.vendorList = response.data;
                this.vendorList.data = this.vendorList.data.map((value, index)=>{
                    value.value = value.id;
                    value.label = value.vendor_name;
                    return value;
                })

                
            })

            },
        getPersonnelType(){
                axios.get(config.api_path+'/reference/settings-list',{
                params:{
                    setting_type:'personnel_type'
                }
                    })
                .then(response => {
                this.personnelTypeList = response.data; 
                })

            },


         getDepot(){
                axios.get(config.api_path+'/reference/settings-list',{
                params:{
                    setting_type:'depot'
                }
                    })
                .then(response => {
                this.depotList = response.data; 
                })

                },
        updateVendorType(data){
	    	this.dataParams.vendor_type = data;
	    },

	    updatePersonnelType(data){
	    	this.dataParams.personnel_type = data;
	    },

	    updateEmploymentType(data){
			console.log(data)
	    	this.dataParams.employment_type = data;
	    },

	    updateVendor(data){
	    	this.dataParams.vendor_id = data;
	    },

	    updateDepot(data){
	    	this.dataParams.depot_id = data;
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    },
        updateGender(data){
	    	this.dataParams.gender = data;
	    },

	    updateCompanyOwned(e){
	    	this.dataParams.company_owned = e;
	    }

 	}
}
</script>
